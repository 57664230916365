<script>
import { Head, Link } from "@inertiajs/inertia-vue3";
import Layout from "@/Pages/Shared/Layout.vue";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

export default {
    components: {
        Link,
        Head,
        Splide,
        SplideSlide,
        SplideTrack
    },
    layout: Layout
};
</script>

<template>
    <Splide class="w-full" :has-track="false" aria-label=""
            :options="{ rewind: true, perPage: 4, pagination: false, breakpoints: {640: {perPage: 1},1100: {perPage: 2}} }">
        <SplideTrack>
            <SplideSlide class="flex">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Apple Watch Seri 7-45mm</div>
                                <div class="font-bold text-textgray text-xs w-full mt-2">Telefon
                                    ve Aksesuar
                                </div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#70d44b"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">20.03.2023</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Spor salonunda test etmek için kiraladım, direkt sıfır olarak alıp heveslenip bir
                            kenara atacağım düşüncesi ile direkt almadım. Ürün tertemiz, özenle hazırlanmış
                            şekilde geldi. Sonrasında zaten başka ürünler de kiraladım.
                        </div>
                        <div class="flex mt-3 items-center">
                            <div
                                class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 pt-2 text-center">
                                SH
                            </div>
                            <div class="ml-2 text-base font-bold">Süleyman H.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Xbox Series X Oyun Konsolu</div>
                                <div class="font-bold text-textgray text-xs w-full mt-2">
                                    Oyun Konsolu & VR
                                </div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#70d44b"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">10.02.2023</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Sorunsuz teslimat,sıkıntısız hizmet,cihazı 1.5 aydır kullanıyorum,herhangi bir
                            sıkıntı yaşamadım,gönül rahatlığıyla kiralayabilirsiniz
                        </div>
                        <div class="flex mt-3 items-center">
                            <div
                                class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 pt-2 text-center">
                                SK
                            </div>
                            <div class="ml-2 text-base font-bold">Selim K.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Logitech G G29 Driving Force Yarış
                                    Direksiyonu
                                </div>
                                <div class="font-bold text-textgray text-xs w-full mt-2">Telefon
                                    ve Aksesuar
                                </div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#70d44b"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">20.02.2023</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Ürünü sipariş ettik iki gün içerisinde sıfır ayarında malzeme geldi hevesimizi aldık
                            ve geri gönderdik sıfır almaya kalksak şimdi atıl bi malzeme olurdu bizim için
                            güvenilir bi sistem uygulama
                        </div>
                        <div class="flex mt-3 items-center">
                            <div
                                class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 pt-2 text-center">
                                NŞ
                            </div>
                            <div class="ml-2 text-base font-bold">Niyazi Ş.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">
                                    iRobot Roomba 693 Robot Süpürge
                                </div>
                                <div class="font-bold text-textgray text-xs w-full mt-2">
                                    Ev Aletleri
                                </div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#70d44b"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">17.03.2023</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Çeşitli sitelerde satın almak için ürün yorumlarını okudum bir türlü karar
                            veremedim. kirala bunu sitesine rastladım. ve ırobot 693 bir aylık kiraladım.sıfır
                            gibi tertemiz bir cihaz tarafıma ulaştırıldı. 1 ay cihazı sorunsuz kullandım.
                            cihazdan genel olarak memnun kaldım. halıyı çok güzel süpürüyor.
                        </div>
                        <div class="flex mt-3 items-center">
                            <div
                                class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 pt-2 text-center">
                                NÇ
                            </div>
                            <div class="ml-2 text-base font-bold">Nejla Ç.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Dyson Purifier Cool Hava Temizleme Fanı
                                </div>
                                <div class="font-bold text-textgray text-xs w-full mt-2">
                                    Ev Aletleri
                                </div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#70d44b"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">13.03.2023</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Odada kötü koku ve toz bırakmıyor. Sabah ferah bi şekilde uyandığınızda çok iyi bir
                            cihaz olduğuna emin oluyorsunuz. Kiralabunu ya ve ekibine teşekkürler
                        </div>
                        <div class="flex mt-3 items-center">
                            <div
                                class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 pt-2 text-center">
                                VA
                            </div>
                            <div class="ml-2 text-base font-bold">Veysel A.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Sony Playstation 5 Oyun Konsolu</div>
                                <div class="font-bold text-textgray text-xs w-full mt-2">
                                    Oyun Konsolu & VR
                                </div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#70d44b"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">03.03.2023</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Ürün elime geldiğinde bir sıkıntı yaşamadım Kiralabunu.çom u kesinlikle tavsiye
                            ediyorum ürünün bana göndermeden önce sıfırmış gibicesine temiz bir şekilde elime
                            ulaştı
                        </div>
                        <div class="flex mt-3 items-center">
                            <div
                                class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 pt-2 text-center">
                                NK
                            </div>
                            <div class="ml-2 text-base font-bold">Necati K.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Apple iPhone 1, 64 GB</div>
                                <div class="font-bold text-textgray text-xs w-full mt-2">Telefon
                                    ve Aksesuar
                                </div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#70d44b"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">10.26.2022</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Süreç tahmin ettiğimden hızlı gerçekleşti. Ekran koruyucu ve kapakta geldi, ürün ile
                            birlikte. Çok faydalı bir hizmet.
                        </div>
                        <div class="flex mt-3 items-center">
                            <div
                                class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 pt-2 text-center">
                                HB
                            </div>
                            <div class="ml-2 text-base font-bold">Hüseyin B.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>
            <SplideSlide class="flex">
                <div class="w-full pl-4 mt-4">
                    <div
                        class="h-full lg:min-h-68 w-full flex flex-col justify-between border-2 hover:border-transparent transition-all hover:shadow-searchshadow border-bordergray rounded-2lg p-3 lg:p-5 md:pt-8">
                        <div class="flex w-full items-start">
                            <div class="w-3/4 pr-4">
                                <div class="font-bold text-sm w-full">Fox Fitness F70 Egzersiz Bisikleti</div>
                                <div class="font-bold text-textgray text-xs w-full mt-2">
                                    Spor Aletleri
                                </div>
                            </div>
                            <div class="w-1/4 md:mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="11.426"
                                     viewBox="0 0 75.764 11.426">
                                    <g id="Group_568" data-name="Group 568"
                                       transform="translate(-758.258 0)">
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(820.246 -2.382)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(804.365 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-2"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(788.414 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-3"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(772.463 -2.432)"
                                            fill="#70d44b"
                                        />
                                        <path
                                            id="star-solid-4"
                                            data-name="star-solid"
                                            d="M13.375,7.605a.8.8,0,0,0-.465-1.442L9.665,6.04a.083.083,0,0,1-.072-.054L8.471,2.958a.8.8,0,0,0-1.5,0L5.85,6a.083.083,0,0,1-.072.054l-3.245.123a.8.8,0,0,0-.465,1.442l2.545,2a.083.083,0,0,1,.029.087l-.876,3.1a.8.8,0,0,0,1.219.883l2.69-1.8a.079.079,0,0,1,.09,0l2.69,1.8a.791.791,0,0,0,1.219-.865L10.79,9.711a.079.079,0,0,1,.029-.087Z"
                                            transform="translate(756.512 -2.432)"
                                            fill="#70d44b"
                                        />
                                    </g>
                                </svg>
                                <div class="text-2xs mt-3 text-checkoutgray w-full">03.06.2022</div>
                            </div>
                        </div>
                        <div class="text-sm md:text-base w-full mt-3 lg:mt-6 font-santralregular">
                            Ürüne dönemsel ihtiyacım vardı. İhtiyacıma yönelik harika bir çözüm oldu.
                        </div>
                        <div class="flex mt-3 items-center">
                            <div
                                class="text-[27px] rounded-full font-santralextrabold border-black border-2 flex justify-center items-center w-16 h-16 pt-2 text-center">
                                ŞU
                            </div>
                            <div class="ml-2 text-base font-bold">Şerafettin U.</div>
                        </div>
                    </div>
                </div>

            </SplideSlide>

        </SplideTrack>

        <div class="splide__arrows">
            <button class="splide__arrow splide__arrow--prev !-left-9">
                <svg class="" xmlns="http://www.w3.org/2000/svg" width="11.926" height="20.737"
                     viewBox="0 0 11.926 20.737">
                    <path id="Path_18" data-name="Path 18"
                          d="M86.422,320.457a1.558,1.558,0,0,1,2.2,0l7.71,7.71,7.71-7.71a1.557,1.557,0,1,1,2.2,2.2l-8.811,8.811a1.558,1.558,0,0,1-2.2,0l-8.811-8.811A1.558,1.558,0,0,1,86.422,320.457Z"
                          transform="translate(331.927 -85.966) rotate(90)" fill="#231f20" />
                </svg>
            </button>
            <button class="splide__arrow splide__arrow--next !-right-9">
                <svg class="rotate-180" xmlns="http://www.w3.org/2000/svg" width="11.926"
                     height="20.737" viewBox="0 0 11.926 20.737">
                    <path id="Path_18" data-name="Path 18"
                          d="M86.422,320.457a1.558,1.558,0,0,1,2.2,0l7.71,7.71,7.71-7.71a1.557,1.557,0,1,1,2.2,2.2l-8.811,8.811a1.558,1.558,0,0,1-2.2,0l-8.811-8.811A1.558,1.558,0,0,1,86.422,320.457Z"
                          transform="translate(331.927 -85.966) rotate(90)" fill="#231f20" />
                </svg>
            </button>
        </div>
    </Splide>
</template>
